import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AppIcon from "@mui/icons-material/ShowChart";
import BalanceWidget from "./BalanceWidget";
import { BankMapping, banksNameMapping } from "../config/Environment";
import { GetAccessToken } from "../services/Keycloak";
import { jwtDecode } from "jwt-decode";
import Backdrop from "@mui/material/Backdrop";
import * as kc from "../services/Keycloak";
import InfoPage from "../pages/InfoPage";

const pages = [
  { title: "Home", url: "/" },
  { title: "Buy", url: "/buy" },
  { title: "Issue", url: "/issue" },
];

const accessToken = (await GetAccessToken())!;
const decodedToken: any = jwtDecode(accessToken);
const username = decodedToken.preferred_username;
const bank = banksNameMapping().find(
  (value: BankMapping, i: number, obj: BankMapping[]) =>
    value.besuUser === username,
)!;

function ResponsiveAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const location = useLocation();
  const [value, setValue] = React.useState<string>(location.pathname);

  const [openInfo, setOpenInfo] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const clickLogout = () => {
    kc.Logout();
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
    setAnchorElUser(null);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AppIcon sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontFamily: "monospace",
              fontWeight: 500,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            SECURITIES
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="TopMenuTabs"
              variant="standard"
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#EEFFEE",
                },
              }}
            >
              {pages.map(({ title, url }) => (
                <Tab
                  key={title}
                  label={title}
                  value={url}
                  to={url}
                  component={Link}
                />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <BalanceWidget />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src={bank.avatar} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleOpenInfo}>
                <Typography sx={{ textAlign: "center" }}>
                  Contract information
                </Typography>
              </MenuItem>
              <MenuItem onClick={clickLogout}>
                <Typography sx={{ textAlign: "center" }}>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={openInfo}
        onClick={handleCloseInfo}
      >
        <InfoPage />
      </Backdrop>
    </AppBar>
  );
}
export default ResponsiveAppBar;
