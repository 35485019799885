import { getKeyCloakSecured, getProxyURL } from "./config/Environment";
import {
  Configuration,
  BondApi,
  OrderApi,
  BalanceApi,
  InfoApi,
} from "./generated-client";
import { GetAccessToken } from "./services/Keycloak";

const getAccessTokenWrapper = async (
  name?: string,
  scopes?: string[],
): Promise<string> => {
  let token = await GetAccessToken();
  if (token == null) {
    // This covers both null and undefined
    return "FAIL_failed_to_retrieve_access_token_from_keycloak";
  }
  return token;
};

const config = new Configuration({
  basePath: getProxyURL(),
});

if (getKeyCloakSecured()) {
  config.accessToken = getAccessTokenWrapper;
}

const bondApi = new BondApi(config);
const balanceApi = new BalanceApi(config);
const orderApi = new OrderApi(config);
const infoApi = new InfoApi(config);

export { bondApi, balanceApi, orderApi, infoApi };
